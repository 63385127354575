<script>
    import { explainList } from '@/api/home.js'

    export default {
        name: 'UserGuide',
        components: {
            Header: () => import('components/base/Header'),
        },
        data() {
            return {
                header:{
                    mainTitle: getWord('rule_explain'),
                },
                content:null,
            }
        },
        props: [

        ],
        methods: {

        },
        mounted() {
            var that = this;
            explainList(this.$route.query.type).then(result => {
                if (result.data.code === 'SUCCESS') {
                    this.content=result.data.result.content;
                    this.CustomScroll(this.$refs.userGuideContent.id);
                }else if(result.data.code === 'FAILUE') {
                    app.Mint.Toast(result.data.msg);
                }
            })
        },
        computed: {

        },
        watch: {

        },
        inject: [

        ],
        updated() {

        }
    };
</script>
<template>    
    <div id="user-guide" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
        />
        <div id="user-guide-content" ref="userGuideContent">
            <div class="inner" v-html="content"></div>
        </div>
    </div>
</template>
<style lang='scss' scoped type="text/css">
#user-guide {
    height: 100%;
    
    #user-guide-content {
        padding: .25rem;
        height: 100%;
        font-size: .28rem;
        overflow: auto;

        .inner {
            padding-bottom: 2rem;
        }

        /deep/ p {
            margin: .1rem 0;
        }
    }
}
</style>